import React from "react";
import Footer from "../components/footer.js"

// markup
const IndexPage = () => {

  const mainStyles = {
    alignItems: "centre",
    textAlign: "center",
    margin: 0,
    padding: 0,
  }
  const headingStyles = {
    color: "#0092be",
    lineHeight: "300px",
    minHeight: "300px",
  }
  
  return (
    <main style={mainStyles}>
      <div style={headingStyles}>
        <h2>Welcome!  Come learn more about the Ultimate Athlete Project by clicking our logo below!</h2>
      </div>
      <Footer />

    </main>
  )
}

export default IndexPage
