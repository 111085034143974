import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import "@fontsource/open-sans" 
import UAPfooter from "../images/UAP-footer.png"

const footerStyles = {
  margin: 0,
  minHeight: "500px",
  padding: 30,
  color: "#ffffff",
  backgroundColor: '#161E2A',
  fontFamily: "Open Sans",
}

export default function Footer() {
  return (
    <div style={footerStyles}>
      <div style={{marginBottom:'10px'}}>
        <OutboundLink href="https://www.theuap.com">
          <img
            width="100px"
            height="50px"
            src={UAPfooter}
            alt="The UAP Logo"
          />
        </OutboundLink>
      </div>
      <div>
        &copy; 2021 Ulty Results LLC
      </div>
    </div>
  )
}